@font-face {
  font-family: "Aeonik";
  src: url("/fonts/Aeonik-Medium.woff2") format("woff2"),
    url("/fonts/Aeonik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("/fonts/Aeonik-Regular.woff2") format("woff2"),
    url("/fonts/Aeonik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("/fonts/Aeonik-Bold.woff2") format("woff2"),
    url("/fonts/Aeonik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("/fonts/Aeonik-Light.woff2") format("woff2"),
    url("/fonts/Aeonik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Butler";
  src: url("/fonts/Butler.woff2") format("woff2"),
    url("/fonts/Butler.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Butler";
  src: url("/fonts/Butler-Medium.woff2") format("woff2"),
    url("/fonts/Butler-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: "Butler";
  src: url("/fonts/Butler-UltraLight.woff2") format("woff2"),
    url("/fonts/Butler-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: "Butler";
  src: url("/fonts/Butler-ExtraBold.woff2") format("woff2"),
    url("/fonts/Butler-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: "Butler";
  src: url("/fonts/Butler-Light.woff2") format("woff2"),
    url("/fonts/Butler-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: "Butler";
  src: url("/fonts/Butler-Black.woff2") format("woff2"),
    url("/fonts/Butler-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: "Butler";
  src: url("/fonts/Butler-Bold.woff2") format("woff2"),
    url("/fonts/Butler-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Aeonik", system-ui, Arial, freesans, sans-serif !important;
  font-weight: 400;
  margin: 0;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
